<template>
  <div v-loading="loading" class="all_box" >
    <div class="all_box_left">
      <div class="Todayis_data">
        <h3>● 商品交易提示 <span class="desc">(您需要立即处理的交易订单)</span></h3>
        <div class="tost_box">
          <div style="display: flex;">
            <div v-for="(item , index) in info.goods_order_data" class="tost_box_item">
                <span  class="tost_box_item_num">{{item.value}}</span>
                <span class="tost_box_item_status">{{item.name}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="Todayis_data">
        <h3>● 代拍交易提示 <span class="desc">(您需要立即处理的交易订单)</span></h3>
        <div class="tost_box">
            <div style="display: flex; width: 110vw;">
              <div v-for="(item , index) in info.buy_order_data" class="tost_box_item">
                  <span  class="tost_box_item_num">{{item.value}}</span>
                  <span class="tost_box_item_status">{{item.name}}</span>
              </div>
            </div>
        </div>
      </div>
      <div class="Todayis_data">
        <h3>● 今日数据</h3>
        <div class="Order_data_box">
          <div class="Order_data_item">
              <span class="money">Ұ{{info.day_order_amount}}</span>
              <span class="desc">已支付订单总金额</span>
          </div>
          <div class="Order_data_item">
              <span class="money">{{info.day_order_num}}单</span>
              <span class="desc">已支付订单数量</span>
          </div>
          <div class="Order_data_item">
              <span class="money">{{info.day_order_total}}单</span>
              <span class="desc">订单数</span>
          </div>
          <div class="Order_data_item">
              <span class="money">{{info.day_order_finish}}</span>
              <span class="desc">完成订单数量</span>
          </div>
          <div class="Order_data_item">
              <span class="money">Ұ{{info.day_order_refund_price}}</span>
              <span class="desc">退款金额</span>
          </div>
        </div>
      </div>
      <div class="Todayis_data">
        <h3>● 数据</h3>
        <div class="Order_data_box">
          <div class="Order_data_item">
              <span class="money">Ұ{{info.yesterday_order_amount}}</span>
              <span class="desc">已支付订单总金额</span>
          </div>
          <div class="Order_data_item">
              <span class="money">{{info.yesterday_order_num}}单</span>
              <span class="desc">已支付订单数量</span>
          </div>
          <div class="Order_data_item">
              <span class="money">{{info.yesterday_order_total}}单</span>
              <span class="desc">订单数</span>
          </div>
          <div class="Order_data_item">
              <span class="money">{{info.yesterday_order_finish}}</span>
              <span class="desc">完成订单数量</span>
          </div>
          <div class="Order_data_item">
              <span class="money">Ұ{{info.yesterday_order_refund_price}}</span>
              <span class="desc">退款金额</span>
          </div>
        </div>
      </div>
      <div class="Todayis_data">
        <h3>● 本月数据</h3>
        <div class="Order_data_box">
          <div class="Order_data_item">
              <span class="money">Ұ{{info.month_order_amount}}</span>
              <span class="desc">已支付订单总金额</span>
          </div>
          <div class="Order_data_item">
              <span class="money">{{info.month_order_num}}单</span>
              <span class="desc">已支付订单数量</span>
          </div>
          <div class="Order_data_item">
              <span class="money">{{info.month_order_total}}单</span>
              <span class="desc">订单数</span>
          </div>
          <div class="Order_data_item">
              <span class="money">{{info.month_order_finish}}</span>
              <span class="desc">完成订单数量</span>
          </div>
          <div class="Order_data_item">
              <span class="money">Ұ{{info.yesterday_order_refund_price}}</span>
              <span class="desc">退款金额</span>
          </div>
        </div>
      </div>

    </div>
    <div class="all_box_right">
      <h3>
        <span>● 单品销售排名</span>
        <span class="small">(当月商家店铺的商品销 量排行)</span>
      </h3>
      <div v-for="(item,index) in info.goods_list" :key="index" class="good_item">
        <el-image fit="contain" class="goods_class_pic" :src="item.goods_pic" lazy></el-image>
        <div class="good_item_right">
          <div class="good_item_title" style="display: flex;">
            <div class="good_item_left">排名:</div>
            <div>
              <div v-if="index==0" class="good_item_small up_1 up">{{item.no}}</div>
              <div v-if="index==1" class="good_item_small up_2 up">{{item.no}}</div>
              <div v-if="index==2" class="good_item_small up_3 up">{{item.no}}</div>
              <div v-if="index > 2" class="good_item_small">{{item.no}}</div>
            </div>
          </div>
          <div class="good_item_title">
            <div class="good_item_left">商品名:</div>
            <span class="good_item_small">{{item.goods_name}}</span>
          </div>
          <div class="good_item_title">
            <div class="good_item_left">成交单:</div>
            <span class="good_item_small">{{item.goods_num}}单</span>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
  import { goods_order_index } from '@/api/xgcApi'
  import { getUploadUrl } from '@/config'
  export default {
    name: '',
    components: {},
    data() {
      return {
        loading:true,
        info:{}
      }
    },
    // computed: {
    //   height() {
    //     return this.$baseTableHeight()
    //   },
    // },
    created() {
      this.getInfo()
    },
    methods: {
      getInfo(){
        this.loading = true
        var data = []
        goods_order_index(data).then(res=>{
          this.loading = false
          this.info = res.data
          console.log(res.data)
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
@import url(./index.scss);
</style>
